import React, { createContext, useState, useContext } from 'react';

export const ModeContext = createContext();

export const ModeProvider = ({ children }) => {
  const [mode, setMode] = useState('Product');

  return (
    <ModeContext.Provider value={{ mode, setMode }}>
      {children}
    </ModeContext.Provider>
  );
};

// Custom hook for using the mode context
export const useMode = () => useContext(ModeContext);
