import React, { useState, useRef, useEffect, useContext } from 'react';
import './layout.css';
import { ModeContext } from './ModeContext';

function DropDownIntegration({secondaryColor}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
const {setMode}=useContext(ModeContext)
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = (item) => {
   setMode(item) ;
    setIsOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${isOpen ? 'dropdown-open' : ''}`}
    >
      <button
        className="btn dropdown-toggle"
        type="button"
        style={{ 'padding': '0px',"color":`${secondaryColor}`,"display":"none" }}
        onClick={toggleDropdown}
      >
        Integration
      </button>

      {isOpen && (
              <ul className="dropdown-menu show" style={{ paddingInline: '0.5em'}}>
          <li>
            <a
              className="dropdown-item nav-link"
              onClick={() => handleItemClick('Control Panel')}
            >
              Control Panel
            </a>
          </li>
          <li>
            <a
              className="dropdown-item nav-link"
              onClick={() => handleItemClick('Product')}
            >
              Product
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}

export default DropDownIntegration;
