import React, { useState, useCallback, useEffect, useRef, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { FiAlignJustify, FiX } from "react-icons/fi";
import ColorModal from "./ColorModal";
import DropDownIntegration from "./DropDownIntegration";
import LogoEditModal from "./LogoEditModal";
import { ColorContext } from "./colorContext";
import { LogoContext } from "./LogoContext";
import imageUrl from '../assets/images/bankLogo.png';
import wIconUrl from '../assets/icons/paylow-w.svg';

export const ResizableNavbar = ({ position, size, onPositionChange, onSizeChange }) => {
  const [isDragging, setIsDragging] = useState(false);
  const { logoName, logoImage, setLogoImage, setLogoName } = useContext(LogoContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const navbarRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { signOut, user } = useAuthenticator();
  const navigate = useNavigate();
  const { primaryColor, secondaryColor } = useContext(ColorContext);

  const closeModal = () => setIsModalOpen(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900 && ['top', 'bottom'].includes(position));
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [position]);

  const updateURLParams = useCallback((name, image) => {
    const currentParams = new URLSearchParams(searchParams);
    currentParams.set('logoName', name);
    if (image) {
      currentParams.set('logoImage', image);
    }
    setSearchParams(currentParams);
  }, [searchParams, setSearchParams]);

  const syncLogoFromURL = useCallback(() => {
    const urlLogoName = searchParams.get('logoName') || 'Demo Host';
    const urlLogoImage = searchParams.get('logoImage') || imageUrl;

    setLogoName(urlLogoName);
    setLogoImage(urlLogoImage);
  }, [searchParams, setLogoName, setLogoImage]);

  useEffect(() => {
    syncLogoFromURL();
    const handleNavigation = () => {
      syncLogoFromURL();
    };
    window.addEventListener('popstate', handleNavigation);
    return () => window.removeEventListener('popstate', handleNavigation);
  }, [syncLogoFromURL]);

  const handleLogoNameChange = (newName) => {
    setLogoName(newName);
    updateURLParams(newName, logoImage);
  };

  const handleLogoImageChange = (newImage) => {
    setLogoImage(newImage);
    updateURLParams(logoName, newImage);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      if (['top', 'bottom'].includes(position)) {
        const newSize = position === 'top' ? e.clientY : window.innerHeight - e.clientY;
        onSizeChange(Math.max(50, Math.min(newSize, 400)));
      } else {
        const newSize = position === 'left' ? e.clientX : window.innerWidth - e.clientX;
        onSizeChange(Math.max(60, Math.min(newSize, 300))); // Changed max width to 300px
      }
    }
  }, [isDragging, position, onSizeChange]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const handleNavigationWithParams = (path) => {
    debugger
    navigate(`${path}?${searchParams.toString()}`);
    setIsMobileMenuOpen(false);
  };

  const renderNavLinks = () => (
    <>
      <li className="nav-link innerIcon" style={{ color: secondaryColor }} onClick={() => handleNavigationWithParams('/balance')}>Balance</li>
      <li className="nav-link innerIcon" style={{ color: secondaryColor }} onClick={() => handleNavigationWithParams('/loans')}>Loans</li>
      <li className="nav-link innerIcon subscription-button" style={{ color: '#f9a054' }} id="btn-subs" onClick={() => handleNavigationWithParams('/subscriptions')}><div className=" d-flex gap-2 mt-3"><img className=" w-icon" src={wIconUrl } /><p>Subscription</p></div></li>
    </>
  );

  const renderNavContent = () => (
    <div style={{ backgroundColor: primaryColor, color: secondaryColor }} className={`nav-content ${['left', 'right'].includes(position) ? 'vertical' : 'horizontal'}`}>
      <div className="nav-section-left">
        <ul className="nav-menu">
          <li>
            <div className="nav-logo" onClick={()=>handleNavigationWithParams('/')} style={{ paddingLeft: '1em' }}>
              {logoImage && <img src={logoImage} alt="Logo" style={{ width: '40px', height: 'auto', marginRight: '10px' }} />}
              <p style={{ color: secondaryColor, paddingTop: '0.7em' }}>{logoName}</p>
            </div>
          </li>
          {renderNavLinks()}
        </ul>
      </div>
      <div className="nav-section-right">
        <ul className="nav-menu" style={{ gap: '0em', color: secondaryColor, marginRight: '1em' }}>
          <li><ColorModal primaryColor={primaryColor} secondaryColor={secondaryColor} onPositionChange={onPositionChange} currentPosition={position} /></li>
          <li className="innerIcon"><DropDownIntegration secondaryColor={secondaryColor} /></li>
          <li className="innerIcon">
            {user && (
              <button className="logout-button" style={{ backgroundColor: secondaryColor, color: primaryColor }} onClick={signOut}>
                Logout
              </button>
            )}
          </li>
        </ul>
      </div>
    </div>
  );

  const renderMobileNavContent = () => (
    <div style={{ backgroundColor: primaryColor, color: secondaryColor, height: '100%', display: 'flex', flexDirection: 'column' }} className={`mobile-nav-content ${['left', 'right'].includes(position) ? 'vertical' : 'horizontal'}`}>
      <div className={`mobile-nav-header ${['left', 'right'].includes(position) ? 'vertical' : 'horizontal'}`}>
        <div className="nav-logo">
          {logoImage && <img src={logoImage} alt="Logo" style={{ width: '40px', height: 'auto', marginRight: '10px' }} />}
          <p style={{ color: secondaryColor, margin: 0 }}>{logoName}</p>
        </div>
        <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FiX size={24} color={secondaryColor} /> : <FiAlignJustify size={24} color={secondaryColor} />}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-menu" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <ul className="mobile-nav-menu" style={{ padding: 0, margin: 0, listStyle: 'none' }}>
            {renderNavLinks()}
            <li><ColorModal primaryColor={primaryColor} secondaryColor={secondaryColor} onPositionChange={onPositionChange} currentPosition={position} /></li>
            <li className="innerIcon" style={{display:'none'}}><DropDownIntegration secondaryColor={secondaryColor} /></li>
          </ul>
          <div>
            {user && (
              <button className="logout-button" style={{ backgroundColor: secondaryColor, color: primaryColor, width: '100%', marginTop: '20px' }} onClick={signOut}>
                Logout
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const getResizingHandleClass = () => {
    switch (position) {
      case 'top':
        return 'resizing-handle-bottom';
      case 'bottom':
        return 'resizing-handle-top';
      case 'left':
        return 'resizing-handle-right';
      case 'right':
        return 'resizing-handle-left';
      default:
        return '';
    }
  };

  return (
    <nav
      ref={navbarRef}
      className={`navigation ${position} ${isMobileView ? 'mobile-view' : 'desktop-view'}`}
      style={['top', 'bottom'].includes(position) ? { height: `${size}px` } : { width: `${size}px` }}
    >
      {isMobileView ? renderMobileNavContent() : renderNavContent()}
      <div onMouseDown={handleMouseDown} className={getResizingHandleClass()} />
      <LogoEditModal
        show={isModalOpen}
        handleClose={closeModal}
        logoName={logoName}
        setLogoName={handleLogoNameChange}
        setLogoImage={handleLogoImageChange}
      />
    </nav>
  );
};