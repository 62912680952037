import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'; 
import './subscriptions.css';
import { ModeContext } from './ModeContext';

const Subscription = ({ paylowCircletInterface }) => {
    const iframeRef = useRef(null);
    const location = useLocation(); 
    const { mode } = useContext(ModeContext);
    useEffect(() => {
        if (paylowCircletInterface) {
            paylowCircletInterface.locateIframe();
        }
    }, [paylowCircletInterface]);

    const searchParams = new URLSearchParams(location.search);
    const queryString = searchParams.toString();

    // Construct iframe source URL based on mode
    const host = process.env.REACT_APP_CIRCLET_HOST;
    const ep = mode === 'Product' ? '' : '/integration';
    const iframeSrc =  `${host}/${ep}?${queryString}&test_mode=true`;

    
    return (
        <div className="subscription-container">
            <iframe
                ref={iframeRef}
                id="paylowCircletIframe"
                name="paylowCircletIframe"
                src={iframeSrc}
                title="PayLow Circlet"
                style={{ width: '100%', height: '100%' }}
            ></iframe>
        </div>
    );
};

export default Subscription;
