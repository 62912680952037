import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import imageURl from '../assets/images/bankLogo.png'
export const LogoContext = createContext();

export const LogoProvider = ({ children }) => {
  const [logoName, setLogoName] = useState('Demo Host');
  const [logoImage, setLogoImage] = useState(imageURl);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // Function to update URL params with logo name and image
  const updateURLParams = useCallback((name, image) => {
    const currentParams = new URLSearchParams(searchParams);
    
    // Update or add logo parameters while keeping existing params
    currentParams.set('logoName', name);
    if (image) {
      currentParams.set('logoImage', image);
    } else {
      currentParams.delete('logoImage'); // Remove if empty
    }

    setSearchParams(currentParams);
  }, [searchParams, setSearchParams]);

  // Get logo name and image from the URL or fallback to localStorage/default values
  const getLogoFromURLOrStorage = useCallback(() => {
    const urlLogoName = searchParams.get('logoName');
    const urlLogoImage = searchParams.get('logoImage');

    const logoNameFromStorage = localStorage.getItem('logoName');
    const logoImageFromStorage = localStorage.getItem('logoImage');

    return {
      name: urlLogoName || logoNameFromStorage || 'Demo Host',
      image: urlLogoImage || logoImageFromStorage || imageURl,
    };
  }, [searchParams]);

  // Sync logo from URL or local storage, and update the URL if needed
  const syncLogo = useCallback(() => {
    const { name, image } = getLogoFromURLOrStorage();

    setLogoName(name);
    setLogoImage(image);

    // Save the logo to localStorage
    localStorage.setItem('logoName', name);
    localStorage.setItem('logoImage', image);

    // Update the URL if logo name or image are not present
    if (!searchParams.get('logoName') || !searchParams.get('logoImage')) {
      updateURLParams(name, image);
    }
  }, [getLogoFromURLOrStorage, searchParams, updateURLParams]);

  // Trigger logo sync on component mount or URL changes
  useEffect(() => {
    syncLogo();
  }, [location, syncLogo]);

  // Function to manually update logo and synchronize it across state, localStorage, and URL
  const updateLogo = useCallback((newName, newImage) => {
    setLogoName(newName);
    setLogoImage(newImage);
    localStorage.setItem('logoName', newName);
    localStorage.setItem('logoImage', newImage);
    updateURLParams(newName, newImage);
  }, [updateURLParams]);

  return (
    <LogoContext.Provider value={{ logoName, logoImage, updateLogo,setLogoImage,setLogoName }}>
      {children}
    </LogoContext.Provider>
  );
};
