import React, { useState, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { ColorContext } from './colorContext';
import { LogoContext } from './LogoContext';

function ColorModal({ currentPosition, onPositionChange }) {
  const { primaryColor, secondaryColor, updateColors } = useContext(ColorContext);
  const { logoName, logoImage, updateLogo } = useContext(LogoContext);

  const [localPrimaryColor, setLocalPrimaryColor] = useState(primaryColor);
  const [localSecondaryColor, setLocalSecondaryColor] = useState(secondaryColor);
  const [localLogoName, setLocalLogoName] = useState(logoName);
  const [localLogoImage, setLocalLogoImage] = useState(logoImage);
  const [localPosition, setLocalPosition] = useState(currentPosition);

  useEffect(() => {
    setLocalPrimaryColor(primaryColor);
    setLocalSecondaryColor(secondaryColor);
    setLocalLogoName(logoName);
    setLocalLogoImage(logoImage);
    setLocalPosition(currentPosition);
  }, [primaryColor, secondaryColor, logoName, logoImage, currentPosition]);

  const handlePrimaryColorChange = (e) => setLocalPrimaryColor(e.target.value);
  const handleSecondaryColorChange = (e) => setLocalSecondaryColor(e.target.value);
  const handleLogoNameChange = (e) => setLocalLogoName(e.target.value);
  const handleLogoImageChange = (e) => setLocalLogoImage(e.target.value);
  const handlePositionChange = (e) => setLocalPosition(e.target.value);

  const handleSaveChanges = () => {
    if (localPrimaryColor !== primaryColor || localSecondaryColor !== secondaryColor) {
      updateColors(localPrimaryColor, localSecondaryColor);
    }
    if (localLogoName !== logoName || localLogoImage !== logoImage) {
      updateLogo(localLogoName, localLogoImage);
    }
    if (localPosition !== currentPosition) {
      onPositionChange(localPosition);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-bg-secondary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{ paddingTop: '1.5em', color: secondaryColor }}
      >
        Customizations
      </button>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel" style={{"color":"black"}}>Select Colors, Logo & Position</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="primaryColorPicker" className="form-label" style={{"color":"black"}}>Primary Color</label>
                <input
                  type="color"
                  id="primaryColorPicker"
                  value={localPrimaryColor}
                  onChange={handlePrimaryColorChange}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="secondaryColorPicker" style={{"color":"black"}} className="form-label">Secondary Color</label>
                <input
                  type="color"
                  id="secondaryColorPicker"
                  value={localSecondaryColor}
                  onChange={handleSecondaryColorChange}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="logoNameInput" className="form-label" style={{"color":"black"}}>Logo Name</label>
                <input
                  type="text"
                  id="logoNameInput"
                  value={localLogoName}
                  onChange={handleLogoNameChange}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="logoImageInput" className="form-label" style={{"color":"black"}}>Logo Image URL</label>
                <input
                  type="url"
                  id="logoImageInput"
                  value={localLogoImage}
                  onChange={handleLogoImageChange}
                  className="form-control"
                />
              </div>
              {localLogoImage && (
                <div className="mb-3">
                  <img
                    src={localLogoImage}
                    alt="Logo Preview"
                    style={{ width: '100px', height: 'auto' }}
                  />
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="positionSelect" className="form-label" style={{"color":"black"}}>Navbar Position</label>
                <select
                  id="positionSelect"
                  value={localPosition}
                  onChange={handlePositionChange}
                  className="form-select"
                >
                  <option value="top">Top Navbar</option>
                  <option value="left">Left Sidebar</option>
                  <option value="right">Right Sidebar</option>
                  <option value="bottom">Bottom Navbar</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ColorModal;