// paylowBackend.js

// Function to get an access token from Cognito
export const getAccessToken = async (authHost, clientId, clientSecret, scope) => {
    try {
        const response = await fetch(authHost, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'grant_type': 'client_credentials',
                'scope': scope,
                'client_id': clientId,
                'client_secret': clientSecret,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to get access token: ${response.status}`);
        }

        const data = await response.json();
        return data.access_token;
    } catch (error) {
        console.error('Error in getAccessToken:', error);
        throw error; // Re-throw the error for higher-level handling
    }
};

// Function to make an API call with the access token
export const makeApiCall = async (baseHost, token, endpoint, payload) => {
    try {
        const response = await fetch(`${baseHost}${endpoint}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error(`Failed to make API call: ${response.status}`);
        }

        const data = await response.json();
        return data.body;
    } catch (error) {
        console.error('Error in makeApiCall:', error);
        throw error; // Re-throw the error for higher-level handling
    }
};
 
export const backend_function_delegateM2M = async (email) => {
  const authHost = `${process.env.REACT_APP_BACKEND_AUTH_HOST}/oauth2/token`;
  const baseHost = process.env.REACT_APP_BACKEND_API_URL;

  const clientId = process.env.REACT_APP_BACKEND_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_BACKEND_CLIENT_SECRET;
  const scope = `product-auth-${process.env.REACT_APP_ENV_NAME}/delegate`;
  const endpoint = "/circlet/m2m_delegate";

  const payload = {
    "email": email,
  };

  try {
    const token = await getAccessToken(authHost, clientId, clientSecret, scope);
    const apiResponse = await makeApiCall(baseHost, token, endpoint, payload);
    console.log('API response:', apiResponse);
    return apiResponse;
  } catch (error) {
    console.error('Error running backend flow:', error);
  }
};
