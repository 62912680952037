
import { fetchAuthSession } from 'aws-amplify/auth';
import { backend_function_delegateM2M } from './paylowBackend';

export async function _paylowHandler_Authenticator(request) {
    const session = await fetchAuthSession();
    return {
        token: session.tokens.idToken.toString(),
    };
}

export async function _paylowHandler_Authenticator_m2m(request) {
    const cachedSession = localStorage.getItem('circletSessionCache');
    if (cachedSession) {
        const parsedSession = JSON.parse(cachedSession);
        if (Math.floor(Date.now() / 1000) < parsedSession.timestamp + parsedSession.ExpiresIn) {
            return {
                token: parsedSession
            };
        }
    }

    const session = await backend_function_delegateM2M('hanan+m2m@paylow.app'); // Replace with you api call
    localStorage.setItem('circletSessionCache', JSON.stringify(session));

    return {
        token: session
    };
}

export async function _paylowHandler_BankLinkRedirector(request) {
    console.log("[Handler] Redirecting to:", request.url);
    window.location.href = request.url;
}

export function loadPaylowCircletInterface(iframeId, allowedDomain) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `${process.env.REACT_APP_CIRCLET_HOST}/assets/cdn/paylowCircletInterface.js`;
        script.async = true;

        script.onload = () => {
            try {
                if (!window.paylowCircletInterface) {
                    reject(new Error('Failed to load the PaylowCircletInterface script'));
                    return;
                }
                console.log("[Client] paylowInterface loaded");
                window.paylowCircletInterface.configure({
                    circletHost: process.env.REACT_APP_CIRCLET_HOST,
                    consentCallbackEndpoint: process.env.REACT_APP_CIRCLET_ENDPOINT,
                });
                console.log("[Client] paylowInterface configured with:", process.env.REACT_APP_CIRCLET_HOST, process.env.REACT_APP_CIRCLET_ENDPOINT);
                resolve(window.paylowCircletInterface);
            } catch (error) {
                reject(error);
            }
        };

        script.onerror = () => {
            reject(new Error('Failed to load the PaylowCircletInterface script'));
        };
        document.body.appendChild(script);
    });
}

