import React from 'react';
import DemiCards from '../DemiCards';
import '../layout.css';  

function Balance() {
  return (
    <div className='demo-page'>
    <div className="balance-container">
      <h1>Balance</h1>
      <div className='demi-body'>
        <DemiCards />
      </div>
      </div>
      </div>
  );
}

export default Balance;