import React from 'react'
import DemiCards from '../DemiCards'
import '../layout.css'
function Benefits() {
  return (
        <div className='demo-page'>
  
    <div className="balance-container">
      <h1>Benefits</h1>
      <div className='demi-body'>
        <DemiCards />
      </div>
      </div>
      </div>
  )
}

export default Benefits