import React, { useState } from "react";
import { ResizableNavbar } from "./ResizableNavBar";
import './layout.css'

const Layout = ({ children }) => {
  const [navbarPosition, setNavbarPosition] = useState('top');
  const [navbarSize, setNavbarSize] = useState(navbarPosition === 'top' || navbarPosition === 'bottom' ? 60 : 250);
  return (
    <div className={`layout-container ${navbarPosition}`} >
      <ResizableNavbar
        position={navbarPosition}
        size={navbarSize}
        onPositionChange={setNavbarPosition}
        onSizeChange={setNavbarSize}
      />
      <main className="main-content">
        {children}
      </main>
    </div>
  );
};

export default Layout;