import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const LogoEditModal = ({ show, handleClose, logoName, setLogoName, setLogoImage }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [newLogoName, setNewLogoName] = useState(logoName);
  const [logoURL, setLogoURL] = useState('');

  useEffect(() => {
    setNewLogoName(logoName);
    const params = new URLSearchParams(location.search);
    const urlLogoImage = params.get('logoImage');
    if (urlLogoImage) {
      setLogoURL(urlLogoImage);
    }
  }, [logoName, location.search]);

  const updateURLParams = useCallback((name, url) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set('logoName', name);
    if (url) {
      currentParams.set('logoImage', url);
    } else {
      currentParams.delete('logoImage');
    }
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }, [location.search]);

  const handleLogoNameChange = (e) => {
    setNewLogoName(e.target.value);
  };

  const handleLogoURLChange = (e) => {
    setLogoURL(e.target.value);
  };

  const handleSaveClick = () => {
    setLogoName(newLogoName);
    setLogoImage(logoURL);
    // updateURLParams(newLogoName, logoURL);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Logo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Logo Name</Form.Label>
            <Form.Control
              type="text"
              value={newLogoName}
              onChange={handleLogoNameChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Logo Image URL</Form.Label>
            <Form.Control
              type="url"
              value={logoURL}
              onChange={handleLogoURLChange}
            />
          </Form.Group>
          {logoURL && (
            <div style={{ marginTop: '10px' }}>
              <img src={logoURL} alt="Logo Preview" style={{ width: '100px', height: 'auto' }} />
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoEditModal;