import React, { useContext, useEffect, useState } from 'react';
import chroma from 'chroma-js';
import { ColorContext } from './colorContext';
import './Home.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import imageUrl from "../assets/images/logo-no-tag-w-3.png";

// Function to get shades of the primary color
const getShades = (baseColor, numShades) => {
  if (!baseColor || !chroma.valid(baseColor)) {
    console.warn(`Invalid base color: ${baseColor}. Falling back to default.`);
    return Array(numShades).fill('#FFFFFF'); // Default to white or any fallback color
  }
  return chroma.scale([chroma(baseColor).darken(1), chroma(baseColor).brighten(1)])
    .mode('lab')
    .colors(numShades);
};

const Home = () => {
  // Retrieve search parameters from URL
  const [searchParams] = useSearchParams();
  const companyName = searchParams.get('logoName');

  const links = [
    { name: "Balance", path: '/balance' },
    { name: "Loans", path: '/loans' },
    { name: "Subscription", path: '/subscriptions' },
    { name: 'Benefits', path: '/benefits' }
  ];

  // Get colors from context
  const { primaryColor, secondaryColor } = useContext(ColorContext);
  const navigate = useNavigate();
  const [shades, setShades] = useState([]);

  // Generate shades when primaryColor changes
  useEffect(() => {
    console.log('Primary Color:', primaryColor);
    setShades(getShades(primaryColor, links.length));
  }, [primaryColor, links.length]);

  const handleNavigate = (path) => {
    navigate(`${path}?${searchParams.toString()}`); // Pass search params on navigation
  };

  return (
    <div className='container'>
      <h1 className="title">Welcome to {companyName}</h1>
      <div className="card-grid">
        {links.map((link, index) => {
          if (link.name === 'Subscription') {
            return (
              <div
                key={link.path}
                className="card subscription-card"
                onClick={() => handleNavigate(link.path)} // Updated navigation
              >
                <div className=''>
                  <p
                    className="link"
                    style={{ color: 'white', margin: 0, marginLeft: '10px' }}
                  >
                    {link.name}
                  </p>
                  <img src={imageUrl} alt="Paylow logo" className="card-logo" />
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={link.path}
                className="card"
                style={{
                  backgroundColor: shades[index] || primaryColor || '#FFFFFF',
                }}
                onClick={() => handleNavigate(link.path)} // Updated navigation
              >
                <p
                  className="link"
                  style={{ color: secondaryColor }}
                >
                  {link.name}
                </p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Home;
