import React, { useContext, useEffect, useState } from 'react';
import { ColorContext } from './colorContext';
import chroma from 'chroma-js';
import './DemiCards.css';

const getShades = (baseColor, numShades) => {
  return chroma.scale([chroma(baseColor).darken(2), chroma(baseColor).brighten(2)])
    .mode('lab')
    .colors(numShades);
};

const generateRows = () => {
  return Array(6).fill().map(() => ({
    divCount: Math.floor(Math.random() * 3) + 1 // Random number between 1 and 3
  }));
};

const DemiCards = () => {
  const { primaryColor } = useContext(ColorContext); // Use context
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(generateRows());
  }, []);

  return (
    <div className="demi-cards">
      {rows.map((row, rowIndex) => {
        const shades = getShades(primaryColor, row.divCount); // Generate shades based on primary color
        return (
          <div key={rowIndex} className={`row div-${row.divCount}`}>
            {Array(row.divCount).fill().map((_, divIndex) => (
              <div
                key={divIndex}
                className="card"
                style={{ backgroundColor: shades[divIndex] }}
              ></div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default DemiCards;
