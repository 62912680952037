import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState('#5A54A4');
  const [secondaryColor, setSecondaryColor] = useState('#ffffff');
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // Function to update URL params with color values
  const updateURLParams = useCallback((primary, secondary) => {
    const currentParams = new URLSearchParams(searchParams);

    // Update or add color parameters while keeping existing params
    currentParams.set('circletPrimaryColor', primary);
    currentParams.set('secondaryColor', secondary);

    setSearchParams(currentParams);
  }, [searchParams, setSearchParams]);

  // Get colors from URL or fallback to localStorage/default values
  const getColorsFromURLOrStorage = useCallback(() => {
    const urlPrimaryColor = searchParams.get('circletPrimaryColor');
    const urlSecondaryColor = searchParams.get('secondaryColor');

    const primaryFromStorage = localStorage.getItem('circletPrimaryColor');
    const secondaryFromStorage = localStorage.getItem('secondaryColor');

    return {
      primary: urlPrimaryColor || primaryFromStorage || '#5A54A4',
      secondary: urlSecondaryColor || secondaryFromStorage || '#FFF',
    };
  }, [searchParams]);

  // Sync colors from URL or local storage, and update the URL if needed
  const syncColors = useCallback(() => {
    const { primary, secondary } = getColorsFromURLOrStorage();

    setPrimaryColor(primary);
    setSecondaryColor(secondary);

    // Save the colors to localStorage
    localStorage.setItem('circletPrimaryColor', primary);
    localStorage.setItem('secondaryColor', secondary);

    // Update the URL if color params are not present
    if (!searchParams.get('circletPrimaryColor') || !searchParams.get('secondaryColor')) {
      updateURLParams(primary, secondary);
    }
  }, [getColorsFromURLOrStorage, searchParams, updateURLParams]);

  // Trigger color sync on component mount or URL changes
  useEffect(() => {
    syncColors();
  }, [location, syncColors]);

  // Function to manually update colors and synchronize across state, localStorage, and URL
  const updateColors = useCallback((newPrimary, newSecondary) => {
    setPrimaryColor(newPrimary);
    setSecondaryColor(newSecondary);
    localStorage.setItem('circletPrimaryColor', newPrimary);
    localStorage.setItem('secondaryColor', newSecondary);
    updateURLParams(newPrimary, newSecondary);
  }, [updateURLParams]);

  return (
    <ColorContext.Provider value={{ primaryColor, secondaryColor, updateColors }}>
      {children}
    </ColorContext.Provider>
  );
};
